import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist`}
          component={lazy(() => import(`./playlist`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/report/cancellation`}
          component={lazy(() => import(`./reports/cancellation`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/report/coupon`}
          component={lazy(() => import(`./reports/coupon`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/report/student`}
          component={lazy(() => import(`./reports/student`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/report/referral-code`}
          component={lazy(() => import(`./reports/referralCode`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/report/free-content`}
          component={lazy(() => import(`./reports/freeContent`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/report/athlete`}
          component={lazy(() => import(`./reports/athlete`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/report/user-balance`}
          component={lazy(() => import(`./reports/userBalance`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/report/videos-bunny`}
          component={lazy(() => import(`./reports/bunny`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/revenue`}
          component={lazy(() => import(`./revenue`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/categories`}
          component={lazy(() => import(`./categories`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/testimonials`}
          component={lazy(() => import(`./testimonials`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/objectives/category/:categoryUUID`}
          component={lazy(() => import(`./categories/objectives`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/library/categories`}
          component={lazy(() => import(`./playlist/library`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/library/categories/:categoryUUID`}
          component={lazy(() => import(`./playlist/library/objective`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/library/categories/objective/:objectiveUUID`}
          component={lazy(() =>
            import(`./playlist/library/objective/technique`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/library/categories/objective/video/:libraryUUID`}
          component={lazy(() => import(`./upload/library/edit`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/lesson/video/:lessonUUID`}
          component={lazy(() => import(`./upload/lesson/edit`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/course`}
          component={lazy(() => import(`./playlist/course`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/lesson`}
          component={lazy(() => import(`./playlist/lesson`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/lesson/:categoryUUID`}
          component={lazy(() => import(`./playlist/lesson/videos`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/:playlistId/category/:categoryId/technique`}
          component={lazy(() => import(`./technique`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/:playlistId/category/:categoryId/:categoryName/objective`}
          component={lazy(() => import(`./objective`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/playlist/:playlistId/category/:categoryId/objective/:objectiveId/technique`}
          component={lazy(() => import(`./technique`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/upload`}
          component={lazy(() => import(`./upload`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/upload/library_vimeo`}
          component={lazy(() => import(`./upload/library`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/upload/library/`}
          component={lazy(() => import(`./upload/library/index_bunny`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/upload/lesson_vimeo`}
          component={lazy(() => import(`./upload/lesson`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/upload/lesson/`}
          component={lazy(() => import(`./upload/lesson/index_bunny`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/upload/course`}
          component={lazy(() => import(`./upload/course`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/upload/course/:courseId`}
          component={lazy(() => import(`./upload/course/edit`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/upload-detail/:videoId`}
          component={lazy(() => import(`./upload/detail`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/user`}
          component={lazy(() => import(`./user`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/user/add`}
          component={lazy(() => import(`./user/add`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/user/add/:uuid`}
          component={lazy(() => import(`./user/add`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/student`}
          component={lazy(() => import(`./student/list`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/student/add`}
          component={lazy(() => import(`./student/add`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/student/add/:uuid`}
          component={lazy(() => import(`./student/add`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/student/profile/:uuid`}
          component={lazy(() => import(`./student/profile`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/coupon`}
          component={lazy(() => import(`./coupon`))}
        />

        <Redirect
          exact
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/home`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
